.infoCourse {
  padding: 230px 0 80px;
}
.infoCourse-wrapper {
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 20px;
  min-height: 430px;
}
.infoCourse-list {
  display: flex;
  align-items: flex-start;
  padding: 50px 95px;
  gap: 100px;
}
.infoCourse-item {
  width: calc(33.33% - 50px);
}
.infoCourse-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  color: #ea0029;
  padding: 50px 0 0 95px;
}
.infoCourse-desc {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}

.infoCourse-info ul li {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  list-style-type: disc;
}
.infoCourse-info-item span {
  color: #4285f4;
}
.infoCourse-pice {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  color: #fbbc04;
}
.inforCour-image {
  position: absolute;
  right: -1px;
  top: -120px;
  max-width: 442px;
}
.inforCour-iframe {
  position: absolute;
  right: -1px;
  top: -120px;
  max-width: 442px;
}
.inforCour-image img {
  width: 100%;
  max-height: 600px;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.inforCour-iframe iframe {
  max-height: 600px;
  height: 55.5vw;
  object-fit: cover;
  border-radius: 20px;
  width: 420px;
}
@media screen and (max-width: 1439px) {
  .infoCourse-item {
    width: calc(33.33% - 40px);
  }
  .inforCour-image {
    width: 420px;
    /* top: -70px; */
  }
  .inforCour-iframe {
    width: 420px;
    /* top: -70px; */
  }
}
@media screen and (max-width: 1279px) {
  .infoCourse-list {
    display: block;
  }
  .infoCourse-item {
    width: 50%;
  }
  .infoCourse-info {
    margin: 20px 0 0 20px;
  }
  .inforCour-image {
    width: 420px;
    top: 50%;
    transform: translateY(-50%);
  }
  .inforCour-iframe {
    width: 420px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 1023px) {
  .infoCourse-item {
    width: 100%;
  }
  .inforCour-image {
    margin: 0 auto;
    position: static;
    width: 420px;
    transform: translateY(0);
    margin-bottom: 50px;
  }
  .inforCour-iframe {
    margin: 0 auto;
    position: static;
    width: 80vw;
    transform: translateY(0);
    margin-bottom: 50px;
    max-width: 800px;
  }
  .inforCour-iframe iframe {
    width: 80vw;
  }
}
@media screen and (max-width: 767px) {
  .infoCourse-heading {
    padding: 20px 0 0 14px;
  }
  .infoCourse-pice {
    margin-left: 20px;
  }
  .infoCourse {
    padding: 100px 0;
  }
  .infoCourse-info {
    margin-top: 40px;
  }
  .inforCour-image {
    margin-top: -100px;
    width: 270px;
    margin-bottom: 20px;
    transform: translateY(30%);
  }
  .inforCour-iframe {
    margin-top: -50px;
    /* width: 270px; */
    margin-bottom: 20px;
    transform: translateY(30%);
  }
  .infoCourse-list {
    padding: 20px 14px;
  }
  .infoCourse-heading {
    font-size: 32px;
    line-height: 35.5px;
    margin-bottom: 12px;
  }
  .infoCourse-desc {
    font-size: 17px;
  }
  .infoCourse-info-item {
    font-size: 17px;
    margin-left: 20px;
    margin-bottom: 5px;
  }
}
