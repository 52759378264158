.feedbackCourse {
  padding-top: 150px;
  margin-bottom: 200px;
}
.feedbackCourse-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.feedbackCourse-feedback {
  width: 50%;
}
.feedbackCourse-faq {
  width: 50%;
}
.feedbackCourse-feedback-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 90px;
}
.feedbackCourse-feedback-list {
  overflow: hidden;
  position: relative;
  height: 320px;
}
.feedbackCourse-next {
  gap: 20px;
  position: absolute;
  left: 120px;
  z-index: 10;
}
.feedbackCourse-next,
.feedbackCourse-prev {
  bottom: -35px;
}
.feedbackCourse-feedback-item.slick-slider {
  position: static;
}
.feedbackCourse-prev.slick-prev,
.feedbackCourse-next.slick-next {
  top: auto;
}
.feedbackCourse-prev {
  gap: 20px;
  position: absolute;
  left: 40px;
  z-index: 10;
}
.feedbackCourse-next i,
.feedbackCourse-prev i {
  font-size: 13px;
  color: #000000;
  line-height: 1.4;
  padding: 10px 15px;
  background-color: #ffffff8e;
  cursor: pointer;
  border-radius: 50%;
}
.feedbackCourse-feedback-item {
  display: flex;
  transition: transform 0.25s linear;
}
.feedbackCourse-feedback-author {
  flex: 1 0 100%;
  display: flex !important;
  gap: 20px;
}
.feedbackCourse-feedback-name {
  font-size: 25px;
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 12px;
  margin-top: 20px;
}
.feedbackCourse-feedback-desc {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  max-width: 345px;
}
.feedbackCourse-feedback-img {
  width: 200px;
  height: 256px;
  object-fit: cover;
  border-radius: 20px;
}
.feedbackCourse-faq-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 90px;
  text-transform: uppercase;
}
.feedbackCourse-faq-process {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  padding: 16px 0 0 0;
}
.feedbackCourse-accordion:not(:first-child) {
  border-top: 1px solid #ffffff;
  padding-top: 16px;
}
.feedbackCourse-accordion-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
  overflow: hidden;
  height: 30px;
  transition: all 0.3s linear;
}
.feedbackCourse-accordion-header.is-active {
  height: auto;
}
.feedbackCourse-accordion-title {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
.feedbackCourse-accordion-icon {
  position: relative;
  margin-right: 20px;
  margin-top: 10px;
}
.feedbackCourse-accordion-icon::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
}
.feedbackCourse-accordion-icon::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(90deg);
  transition: 0.2s all linear;
}
.feedbackCourse-accordion-icon.is-active::before {
  transform: rotate(0deg);
}
.feedbackCourse-accordion-text {
  max-width: 350px;
  margin-left: 20px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  opacity: 0;
  transition: all 0.3s linear;
}
.feedbackCourse-accordion-text.is-active {
  opacity: 1;
}
@media screen and (max-width: 1199px) {
  .feedbackCourse-accordion-text {
    max-width: 60%;
  }
  .feedbackCourse-wrapper {
    margin-bottom: 50px;
  }
  .feedbackCourse-feedback-author {
    gap: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .feedbackCourse-wrapper {
    display: block;
  }
  .feedbackCourse-feedback {
    width: 100%;
    margin-bottom: 150px;
  }
  .feedbackCourse-faq {
    width: 100%;
  }
  .feedbackCourse-feedback-desc {
    max-width: 100%;
  }
  .feedbackCourse-feedback-img {
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .feedbackCourse-feedback-author {
    display: block !important;
  }
  .feedbackCourse-feedback {
    margin-bottom: 0;
  }
  .feedbackCourse-wrapper {
    margin-bottom: 80px;
  }
  .feedbackCourse-faq {
    margin-top: 50px;
  }
  .feedbackCourse {
    padding-top: 80px;
    margin-bottom: 80px;
  }
  .feedbackCourse-feedback-heading {
    margin-bottom: 50px;
  }
  .feedbackCourse-feedback-author {
    display: block;
  }
  .feedbackCourse-faq-heading {
    margin-bottom: 50px;
  }
  .feedbackCourse-accordion-text {
    display: none;
  }
  .feedbackCourse-accordion-text.is-active {
    display: block;
  }
  .feedbackCourse-accordion-title.is-active {
    display: none;
  }
  .feedbackCourse-accordion-text {
    max-width: 80%;
    margin-left: 0;
  }
  .feedbackCourse-feedback-list {
    height: 100%;
  }
  .feedbackCourse-feedback-name {
    text-align: center;
    margin-top: 20px;
  }
  .feedbackCourse-feedback-img {
    margin: 0 auto;
  }
  .feedbackCourse-next {
    left: 85%;
  }
  .feedbackCourse-prev.slick-prev,
  .feedbackCourse-next.slick-next {
    top: 25%;
  }
  .feedbackCourse-next,
  .feedbackCourse-prev {
    bottom: auto;
  }
  .feedbackCourse-prev {
    left: 5%;
  }
}
@media screen and (max-width: 374px) {
  .feedbackCourse-prev {
    left: 3%;
  }
}
