.project-detail h1 {
  margin-bottom: 20px;
}
.project-detail h2 {
  margin-bottom: 20px;
}

.project-detail .project-detail-info {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-column-gap: 10px;
}
.project-detail .project-detail-info-item:first-child {
  grid-row: 1 / 3;
}

.project-detail .project-detail-info-item {
  margin-bottom: 30px;
}
.project-detail .project-detail-info-item img {
  width: 25rem;
  height: 16rem;
  object-fit: scale-down;
  border-radius: 15px;
}
.project-detail .project-detail-info-item label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.project-detail .project-detail-info-item p {
  font-size: 17px;
}
.project-detail-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px 20px 0;
}
.project-detail-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
.project-detail-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
.project-detail-tags {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
.project-detail-tags span {
  font-size: 1.2rem;
}
.project-image-detail {
  margin-top: 20px;
  margin-bottom: 20px;
}
.project-image-detail-item img {
  width: 25rem;
  height: 16rem;
  object-fit: cover;
  border-radius: 15px;
}
.project-image-detail-item {
  margin-bottom: 20px;
}
.project-image-detail p {
  font-size: 1.2rem;
}
.project-detail-tag-item {
  position: relative;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: var(--color-primary);
  color: var(--color-white);
}
.project-detail-tag-item-delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, -50%);
  color: var(--color-white);
  background-color: var(--color-danger);
  padding: 4px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.project-detail-tag-item:hover .project-detail-tag-item-delete {
  opacity: 1;
}
.project-detail-tag-item-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, 50%);
  color: var(--color-white);
  background-color: var(--color-info-dark);
  padding: 5px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.project-detail-tag-item:hover .project-detail-tag-item-edit {
  opacity: 1;
}
