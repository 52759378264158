.expertise {
  margin-top: 300px;
}
.expertise-heading {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 80px;
}
.expertise-solution {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 250px;
}
.expertie-solution-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 70px;
  text-align: center;
}
.expertie-solution-title span {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.expertie-solution-info {
  position: relative;
  width: 290px;
  height: 290px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.expertie-solution-content {
  position: absolute;
  text-align: center;
  width: 200px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.expertise-process {
  padding-top: 190px;
}
.expertise-process-heading {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 100px;
}
.expertise-process-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 200px;
}
.expertise-process-cricle {
  position: relative;
  width: 290px;
  height: 290px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.expertise-process-item {
  position: relative;
  animation: scrollTopToBottom 2s linear;
  transform: translateY(-200px);
  opacity: 0;
  transition: all 1s linear;
}
.expertise-process-item.active {
  transform: translateY(0);
  opacity: 1;
}
.expertise-process-item.item-1 {
  left: 40px;
}
.expertise-process-item.item-2 {
  left: 20px;
  transition-delay: 0.5s;
}
.expertise-process-item.item-3 {
  transition-delay: 1s;
}
.expertise-process-item.item-4 {
  left: -20px;
  transition-delay: 1.5s;
}
.expertise-process-item.item-5 {
  left: -40px;
  transition-delay: 2s;
}
.expertise-process-name {
  position: absolute;
  text-align: center;
  width: 200px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.expertise-process-name span {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.expertise-process-info {
  text-align: center;
  margin-top: 35px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
@media screen and (max-width: 1439px) {
  .expertise-process-cricle {
    width: 260px;
    height: 260px;
  }
}
@media screen and (max-width: 1279px) {
  .expertise-solution {
    gap: 200px;
  }
  .expertie-solution-title {
    margin-bottom: 50px;
  }
  .expertie-solution-info {
    width: 260px;
    height: 260px;
  }
  .expertise-process {
    padding-top: 150px;
  }
  .expertise-process-cricle {
    width: 210px;
    height: 210px;
  }
  .expertise-process-name {
    font-size: 23px;
  }
  .expertise-process-info {
    font-size: 15px;
  }
}
@media screen and (max-width: 1023px) {
  .expertise-process-list {
    display: block;
  }
  .expertise-process-item {
    margin-bottom: 80px;
  }
  .expertise-process-item:last-child {
    margin-bottom: 0;
  }
  .expertise-process-list {
    margin-bottom: 80px;
  }
  .expertise-process-item.item-1 {
    left: 0;
    transition-delay: 0s;
  }
  .expertise-process-item.item-2 {
    left: 0;
    transition-delay: 0s;
  }
  .expertise-process-item.item-3 {
    transition-delay: 0s;
  }
  .expertise-process-item.item-4 {
    left: 0;
    transition-delay: 0s;
  }
  .expertise-process-item.item-5 {
    left: 0;
    transition-delay: 0s;
  }
  .expertise-process-cricle {
    margin: 0 auto;
    width: 260px;
    height: 260px;
  }
  .expertise-process-info {
    margin-top: 25px;
  }
  .expertise-process-name {
    font-size: 26px;
  }
  .expertise-process-info {
    font-size: 17px;
  }
}
@media screen and (max-width: 767px) {
  .expertise-solution {
    display: block;
  }
  .expertise-process {
    padding-top: 100px;
  }
  .expertie-solution-info {
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .expertise {
    margin-top: 150px;
  }
  .expertie-solution-item:last-child {
    margin-top: 100px;
  }
}
