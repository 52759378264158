.blogRelated {
  padding: 190px 0 140px;
  overflow: hidden;
}
.blogRelated-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}
@media screen and (max-width: 1279px) {
  .blogRelated-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .blogRelated-list a {
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .blogDetail-heading {
    font-size: 23px;
  }
  .blogDetail-top {
    padding: 20px;
    margin-bottom: 30px;
  }
  .blogRelated {
    padding: 80px 0;
  }
  .blogRelated-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
