.podcastPage h1 {
  margin-bottom: 20px;
}
.podcastPage h2 {
  margin: 20px 0;
}
.podcastPage .podcastPage-header,
.podcastPage .podcastPage-info {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-column-gap: 10px;
}
.podcastPage .podcastPage-header-flex {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / 3;
  flex-wrap: wrap;
  margin-top: 10px;
}
.podcastPage .podcastPage-header-link {
  display: inline-block;
  margin-bottom: 20px;
}
.podcastPage .podcastPage-header-flex label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.podcastPage .podcastPage-info-item {
  margin-bottom: 10px;
}
.podcastPage .podcastPage-header-flex-item img {
  width: 16rem;
  height: 12rem;
  object-fit: scale-down;
  background-color: black;
  border-radius: 15px;
}

.podcastPage .podcastPage-header-item:first-child {
  grid-row: 1 / 3;
}
.podcastPage .podcastPage-header-item.item-about {
  grid-row: 1 / 2;
}
.podcastPage .podcastPage-header-item.item-course {
  grid-row: 1 / 4;
}
.podcastPage .podcastPage-info .podcastPage-header-item:first-child {
  grid-row: 1 / 2;
}
.podcastPage .podcastPage-header-item {
  margin-bottom: 30px;
}
.podcastPage .podcastPage-header-item img {
  width: 25rem;
  height: 16rem;
  object-fit: scale-down;
  border-radius: 15px;
}
.podcastPage .podcastPage-header-item.item-course img {
  width: 25rem;
  height: 32rem;
  object-fit: scale-down;

  border-radius: 15px;
}
.podcastPage .podcastPage-info .podcastPage-header-item img {
  width: 10rem;
  height: 10rem;
  object-fit: scale-down;
  border-radius: 15px;
}
.podcastPage .podcastPage-header-item label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.podcastPage .podcastPage-header-item p {
  font-size: 17px;
}
.podcastPage .podcastPage-header-item .project-image {
  background-color: black;
  object-fit: scale-down;
}
.podcastPage-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px 20px 0;
}
.podcastPage-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
.podcastPage-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
.podcastPage .podcastPage-info {
  margin-top: 10px;
}
.podcastPage .podcastPage-info-item label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.podcastPage .podcastPage-info-item p {
  font-size: 17px;
}
.podcastPage .workpage-tags-item-img {
  display: block;
  position: relative;
  width: 190px;
  height: 190px;
  border-radius: 15px;
  background-color: var(--color-primary);
  transition: all 0.5s linear;
}
.podcastPage .workpage-tags-item-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.podcastPage .workpage-tags-item-img:hover .workpage-tags-item-edit {
  opacity: 1;
}
