.form-podcast form {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 15px;
  margin-bottom: 20px;
}
.form-podcast form .form-project-item:first-child {
  grid-column: 1 / 3;
}
