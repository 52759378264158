.course {
  padding: 200px 0 0 0;
}
.course-dpi {
  margin-bottom: 180px;
  overflow: hidden;
  height: 830px;
}
.course-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 80px;
}
.course-flex {
  position: relative;
  z-index: 0;
}
.course-list {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2%; */
  display: flex;
  transition: all 0.3s linear;
}
.course-list a {
  flex: 1 0 33.33%;
}
.course-item {
  position: relative;
  margin: 0 5%;
  cursor: pointer;
  transition: all 0.3s linear;
  max-width: 420px;
}

.course-item-image {
  width: 100%;
  position: relative;
}
.course-img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}
.course-img.course-img-single {
  border-radius: 20px;
  /* width: 30vw; */
  height: 37vw;
  margin: 0 auto;
  object-fit: cover;
  max-height: 541px;
}
.course-item-image iframe.course-img-single {
  border-radius: 20px;
  /* width: 30vw; */
  height: 37vw;
  margin: 0 auto;
  object-fit: cover;
  max-height: 541px;
}
iframe.course-img {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  height: 36.5vw;
  margin: 0 auto;
  object-fit: cover;
  max-height: 541px;
  max-width: 420px;
}
.course-next {
  position: absolute;
  z-index: 10;
  top: 45%;
  right: 2.3%;
  transform: translateY(-50%);
}
.course-prev {
  position: absolute;
  z-index: 10;
  top: 45%;
  left: 0.35%;
  transform: translateY(-50%);
}
.slick-prev:before,
.slick-next:before {
  content: '';
}
.course-next i,
.course-prev i {
  font-size: 17px;
  color: #000000;
  line-height: 1.3;
  padding: 13px 19px;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 10px 1px #0000005d;
}

.course-name {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  margin: 35px auto 20px;
  max-width: 28vw;
  color: #ffffff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.course-border {
  max-width: 1300px;
  position: absolute;
  width: 90vw;
  height: 108%;
  border-radius: 20px;
  border: 1px solid #ffffff;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.course-cbs {
  overflow: hidden;
  height: 830px;
}

.course-item-coming {
  position: absolute;
  width: 100%;
  height: 102%;
  background-color: #000000b0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s linear;
}
.course-coming-heading {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.4;
  padding: 8px 20px;
  border-radius: 14px;
  background-color: #ffffff;
  color: #000000;
}
.course-item-link.coming {
  pointer-events: none;
}
.course-item-link.coming .course-item-image .course-item-coming {
  opacity: 1;
}
@media screen and (max-width: 1439px) {
  .course-img.course-img-single {
    width: 28.56vw;
  }
}
@media screen and (max-width: 1279px) {
  .course-next {
    right: 2.9%;
  }
  .course-dpi {
    margin-bottom: 150px;
  }
}
@media screen and (max-width: 1200px) {
  .course-dpi {
    margin-bottom: 80px;
    height: 700px;
  }
  .course-cbs {
    height: 700px;
  }
  .course {
    padding: 200px 0 50px;
  }
}
@media screen and (max-width: 1023px) {
  .course {
    padding: 120px 0;
  }
  .course-item {
    margin: 0 2%;
  }
  .course-next {
    right: 3.8%;
    top: 41%;
  }
  .course-prev {
    top: 41%;
  }
  .course-list a {
    flex: 1 0 50%;
  }
  .course-border {
    width: 85vw;
    height: 105%;
  }
  .course-name {
    margin: 30px auto;
    max-width: 32vw;
    font-size: 23px;
  }
  .course-img {
    width: 95%;
    margin: 0 auto;
    object-fit: cover;
  }
  .course-dpi {
    height: 640px;
    margin-bottom: 170px;
  }
  .course-cbs {
    height: 800px;
  }
  .course-item-coming {
    width: 30vw;
  }
}
@media screen and (max-width: 768px) {
  .course-img.course-img-single {
    width: 40vw;
    height: 50vw;
  }
  .course-item-image iframe.course-img-single {
    width: 100vw;
    height: 100vw;
    max-height: 500px;
    max-width: 420px;
  }
  iframe.course-img {
    height: 55.5vw;
    max-height: 541px;
    max-width: 420px;
  }
  .course-next {
    right: 5.4%;
  }
  .course-dpi {
    height: 100%;
    margin-bottom: 170px;
  }
  .course-cbs {
    height: 100%;
  }
  .course-name {
    margin: 20px auto;
    font-size: 23px;
    max-width: 40vw;
  }
  .course-item-coming {
    width: 40vw;
    height: 101%;
    border-radius: 20px;
  }
}
@media screen and (max-width: 480px) {
  .course-img.course-img-single {
    width: 92%;
    height: 100%;
  }
  .course-item-image iframe.course-img-single {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    height: 100vw;
    max-height: 500px;
    max-width: 420px;
  }
  iframe.course-img {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    min-height: 495px;
    max-width: 420px;
  }
  .course {
    padding: 120px 0 80px 0;
  }
  .course-name {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .course-next {
    right: 9%;
    top: 40%;
  }
  .course-prev {
    left: 2.4%;
    top: 40%;
  }
  .course-list a {
    flex: 1 0 100%;
  }
  .course-border {
    width: 80vw;
    height: 104%;
  }
  .course-dpi {
    margin-bottom: 120px;
  }
  .course-dpi:last-child {
    margin-bottom: 0;
  }
  .course-cbs {
    margin-bottom: 30px;
  }
  .course-item-coming {
    width: 100%;
    height: 101%;
    border-radius: 20px;
  }
}
@media screen and (max-width: 424px) {
  .course-next {
    right: 9.5%;
  }
  .course-prev {
    left: 1.4%;
  }
  iframe.course-img {
    min-height: 418px;
  }
}
@media screen and (max-width: 374px) {
  iframe.course-img {
    min-height: 357px;
  }
}
