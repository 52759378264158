.blogDetail {
  padding-top: 100px;
}
.blogDetail-view {
  max-width: 720px;
  margin: 0 auto;
}
.blogDetail-top {
  border: 1px solid #ffffff;
  border-radius: 20px;
  padding: 38px;
  text-align: center;
  margin-bottom: 70px;
}
.blogDetail-heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 32.5px;
  color: #4285f4;
}
.blogDetail-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
  color: #ffffff;
}
.blogDetail-desc {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 30px;
  color: #ffffff;
}
.blogDetail-desc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10px 0;
}
