.studentCourse {
  padding: 200px 0 50px;
}
.studentCourse-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 100px;
}
.studentCourse-wrapper {
  overflow: hidden;
}
.studentCourse-list {
  display: flex;
  gap: 20px;
  pointer-events: none;
}
.studentCourse-list .slick-list .slick-track {
  display: flex;
  gap: 30px;
}
.studentCourse-list .slick-prev,
.studentCourse-list .slick-next {
  display: none !important;
}
.studentCourse-imgae {
  width: 100%;
  max-width: 440px;
  max-height: 266px;
  border-radius: 20px;
  object-fit: cover;
}
.studentCoure-item iframe {
  width: 100%;
  height: 100vw;
  max-width: 440px;
  max-height: 260px;
  object-fit: cover;
  border-radius: 20px;
}
.studentCoure-item {
  border-radius: 20px;
}
.studentCourse-name {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 30px;
}
@media screen and (max-width: 1023px) {
  .studentCoure-item iframe {
    max-height: 198px;
  }
}
@media screen and (max-width: 480px) {
  .studentCourse {
    padding: 80px 0 0 0;
  }
  .studentCoure-item {
    flex: 1 0 33.33%;
  }
  .studentCourse-heading {
    margin-bottom: 40px;
  }
  .studentCoure-item iframe {
    max-height: 250px;
  }
}
