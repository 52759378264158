* {
  margin: 0;
  padding: 0;
  font-family: 'Lexend', serif;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  background-color: #000000;
}
body .wrapper {
  font-size: 16px;
  line-height: 1;
}
li {
  list-style-type: none;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (max-width: 1439px) {
  .container {
    max-width: 95%;
  }
}
.wrapper {
  background-color: #000000;
  color: #ffffff;
}
/* @media screen and (max-width: 1279px) {
  .container {
    max-width: 1000px;
  }
} */
/* Tùy chỉnh cho toàn bộ thanh cuộn */
::-webkit-scrollbar {
  width: 5px; /* Độ rộng của thanh cuộn */
  height: 3px; /* Độ cao của thanh cuộn (dùng cho cuộn ngang) */
}

/* Tùy chỉnh cho phần thanh trượt (thumb) */
::-webkit-scrollbar-thumb {
  background-color: #ea0029; /* Màu sắc của thanh trượt */
  border-radius: 10px; /* Bán kính bo tròn */
  border: 2px solid #ea0029; /* Viền xung quanh thanh trượt */
}

/* Tùy chỉnh cho phần nền của thanh cuộn */
::-webkit-scrollbar-track {
  background: #222222; /* Màu nền */
}
