.navAdmin {
  margin-top: 1.4rem;
}

.navAdmin .nav {
  display: flex;
  justify-content: end;
  gap: 2rem;
}

.navAdmin .nav button {
  display: none;
}

.navAdmin .dark-mode {
  background-color: var(--color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}

.navAdmin .dark-mode span {
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navAdmin .dark-mode span.active {
  background-color: var(--color-primary);
  color: white;
  border-radius: var(--border-radius-1);
}

.navAdmin .nav .profile {
  display: flex;
  gap: 2rem;
  text-align: right;
}

.navAdmin .nav .profile .profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.navAdmin .user-profile {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: all 0.3s ease;
}

.navAdmin .user-profile:hover {
  box-shadow: none;
}

.navAdmin .user-profile img {
  width: 11rem;
  height: auto;
  margin-bottom: 0.8rem;
  border-radius: 50%;
}

.navAdmin .user-profile h2 {
  margin-bottom: 0.2rem;
}
