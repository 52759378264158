.resgiter-user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: var(--color-background);
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  gap: 30px;
  margin: 0 auto;
}
.resgiter-user form {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 15px;
}
.resgiter-user-item {
  margin-bottom: 30px;
}
.resgiter-user-item label {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
}
.resgiter-user-item input {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
}
.resgiter-user-item input::placeholder {
  opacity: 0.5;
}
.resgiter-user-item .err {
  color: red;
  margin-left: 10px;
  margin-top: 10px;
}
.resgiter-user-item button {
  padding: 14px 34px;
  border-radius: 15px;
  background-color: var(--color-info-dark);
  color: var(--color-white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.resgiter-user-item button:hover {
  background-color: var(--color-info-light);
  color: var(--color-dark);
}

.resgiter-user-link {
  display: flex;
  justify-content: flex-end;
}
.resgiter-user-link a {
  color: var(--color-info-dark);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}
.resgiter-user-link a:hover {
  color: var(--color-info-light);
}
