.work {
  padding-top: 0px;
}
.work-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 90px;
}
.work-heading {
  position: relative;
  width: 50%;
  font-size: 26px;
  line-height: 1.3;
  font-weight: 400;
  overflow: hidden;
}
.work-heading .slick-slider {
  position: absolute;
  left: 225px;
  top: 0;
  pointer-events: none;
}

.work-heading .slick-slider .slick-list {
  height: 36px !important;
}
.work-heading .slick-slider .slick-prev {
  display: none;
  pointer-events: none;
}
.work-heading .slick-slider .slick-next {
  display: none;
  pointer-events: none;
}
.work-drop {
  color: #ea0029;
  display: inline-block;
}
.work-list {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  grid-column-gap: 20px;
  grid-row-gap: 90px;
  margin-bottom: 100px;
}
.work-item.coming .work-item-image .work-item-coming {
  opacity: 1;
}
.work-item-image {
  overflow: hidden;
  position: relative;
  max-width: 680px;
  height: 29vw;
  max-height: 430px;
  border-radius: 20px;
}
.work-item-image iframe {
  overflow: hidden;
  position: relative;
  max-width: 680px;
  height: 29vw;
  max-height: 430px;
  border-radius: 20px;
}
.work-item-coming {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  transition: all 0.2s linear;
}
.work-item-link.coming {
  pointer-events: none;
}
.work-item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.2s linear;
}
.work-item:hover .work-item-img {
  transform: scale(1.05);
}
.work-title {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.4;
  margin: 30px 0 10px;
  color: #ffffff;
}
.work-tag {
  display: flex;
  gap: 30px;
}
.work-tag-item {
  position: relative;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.3;
  margin-left: 20px;
  color: #ffffff;
  z-index: 0;
}
.work-tag-item::before {
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  border-radius: 50%;
  left: -14px;
  top: 10px;
  transform: translate(-50%, -50%);
}
.work-coming-heading {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.4;
  padding: 8px 20px;
  border-radius: 14px;
  background-color: #ffffff;
  color: #000000;
}
.work-pagination {
  display: flex;
  justify-content: center;
  gap: 70px;
  margin: 50px 0 200px 0;
}
.work-pagination li {
  display: inline-block;
  cursor: pointer;
}
.work-pagination a {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;
  transition: all 0.3s linear;
}
.work-pagination a:hover {
  color: #ea0029;
}
.work-pagination li:not(:first-child):not(:last-child) {
  display: none;
}

@media screen and (max-width: 1279px) {
  .work-heading {
    font-size: 23px;
  }
  .work-heading .slick-slider {
    left: 200px;
  }
}
@media screen and (max-width: 1023px) {
  .work-heading {
    font-size: 17px;
    line-height: 1.3;
  }
  .work-coming-heading {
    font-size: 17px;
    line-height: 1.3;
    padding: 8px 16px;
  }
  .work-heading .slick-slider {
    left: 145px;
  }
}
@media screen and (max-width: 767px) {
  .work-heading {
    width: 100%;
    font-size: 14px;
  }
  .work-wrapper {
    margin-bottom: 40px;
  }
  .work-list {
    display: grid;
    grid-template-columns: repeat(1, 6fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    margin-bottom: 60px;
  }
  .work-coming-heading {
    font-size: 17px;
    line-height: 1.3;
    padding: 8px 16px;
  }
  .work-title {
    font-size: 17px;
    line-height: 1.3;
    margin: 25px 0 10px;
  }
  .work-tag-item {
    font-size: 14px;
  }
  .work-tag-item::before {
    left: -10px;
    top: 11px;
  }
  .work-heading .slick-slider {
    left: 120px;
  }
  .work-item-image {
    height: 100%;
    max-height: 430px;
  }
  .work-item-image img {
    height: 100%;
  }
  .work-item-image iframe {
    height: 55vw;
  }
  .work-pagination {
    margin: 40px 0 80px 0;
  }
}
@media screen and (max-width: 479px) {
  .work-item-image {
    height: 100%;
  }
  .work-item-image img {
    height: 100%;
  }
  .work-item-image iframe {
    height: 55vw;
  }
}
