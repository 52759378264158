.podcast-banner {
  width: 100%;
  height: 610px;
  padding-top: 100px;
}
.podcast-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.podcast-marquee-item p {
  margin-left: 100px;
  font-size: 25px;
  line-height: 1.4;
  font-weight: 700;
}
.podcast-desc {
  margin-top: 80px;
  font-size: 45px;
  line-height: 1.3;
  font-weight: 400;
  background: linear-gradient(#ea0029, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-emphasis-color: transparent;
  color: transparent;
}

.podcast-author {
  padding-top: 200px;
}
.podcast-author-top {
  display: flex;
  justify-content: flex-end;
}
.podcast-author-heading {
  width: 50%;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 90px;
}
.podcast-author-view {
  display: flex;
  align-items: center;
  gap: 60px;
  position: relative;
}
.podcast-author-view a:first-child {
  color: #ffffff;
  pointer-events: none;
}
.podcast-author-view a .podcast-author-item {
  color: #ffffff;
}
.podcast-author-item {
  display: block;
  position: relative;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  background-color: #ea0029;
  transition: all 1s linear;
}
.podcast-author-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.podcast-author-item:not(:first-child) {
  position: absolute;
  right: 86%;
}
.podcast-author-item.active.item-4 {
  right: 0;
}
.podcast-author-item.item-3 {
  transition-delay: 0.5s;
}
.podcast-author-item.active.item-3 {
  right: 18%;
}
.podcast-author-item.active.item-2 {
  right: 36%;
}
.podcast-author-item.item-2 {
  transition-delay: 1s;
}
.podcast-author-item.item-1::before {
  content: '';
  width: 0px;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(8%, -50%);
  transition: all 2s linear;
  transition-delay: 1.5s;
}
.podcast-author-item.item-1::after {
  content: '>';
  position: absolute;
  font-size: 15px;
  top: 49%;
  left: 120%;
  transform: translateY(-50%);
  transition: all 0.5s linear;
  transition-delay: 1.5s;
  opacity: 0;
}

.podcast-author-item.active.item-1::after {
  left: 639px;
  opacity: 1;
}
.podcast-author-item.active.item-1::before {
  width: 100vw;
  max-width: 420px;
}
.podcast-marquee-text {
  text-align: center;
  font-size: 100px;
  font-weight: 400;
  line-height: 1.4;
}
.podcast-marquee {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: 100px;
}
.podcast-marquee span {
  will-change: transform;
  transform: translateX(0);
  white-space: nowrap;
  animation: marqueePodcast 20s linear infinite;
}

@keyframes marqueePodcast {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.podcast-info {
  margin: 200px 0;
}
.podcast-info-list {
  display: flex;
}
.podcast-info-main {
  width: calc(50%);
  border-radius: 50%;
  cursor: pointer;
}

.podcast-info-main-image {
  margin: 0 auto;
  max-width: 640px;
  height: 640px;
  border-radius: 50%;
  position: relative;
}
.podcast-info-main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.podcast-info-main-image::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff00;
  border-radius: 100%;
  transition: all 0.4s ease-out;
  opacity: 1;
  z-index: -1;
}
.podcast-info-main-image:hover::before {
  width: 100%;
  height: 100%;
  background-color: #ffffffa8;
  opacity: 0;
  z-index: 2;
}

.podcast-info-gid {
  width: calc(50%);
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}
.podcast-info-gid-item {
  position: relative;
  width: 190px;
  height: 190px;
  border-radius: 50%;
  transition: 0.5s all linear;
  margin: 0 auto;
}
.podcast-info-gid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.podcast-info-gid-item:hover {
  transform: rotateY(180deg);
}
.podcast-info-gid-item:nth-child(1),
.podcast-info-gid-item:nth-child(3),
.podcast-info-gid-item:nth-child(9) {
  background-color: #ea0029;
}
.podcast-info-gid-item:nth-child(2) {
  background-color: #ff7db0;
}
.podcast-info-gid-item:nth-child(5) {
  background-color: #fbbc04;
}
.podcast-info-gid-item:nth-child(6) {
  background-color: #4285f4;
}
.podcast-info-gid-item:nth-child(7) {
  background-color: #34a853;
}
.podcast-info-gid-text {
  position: absolute;
  width: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
@media screen and (max-width: 1439px) {
  .podcast-author-item.active.item-1::before {
    max-width: 340px;
  }
  .podcast-author-item.active.item-1::after {
    left: 550px;
    opacity: 1;
  }
  .podcast-author-view {
    gap: 50px;
  }
  .podcast-author-heading {
    width: 50%;
    font-size: 27px;
  }
  .podcast-info-main-image {
    max-width: 580px;
    height: 580px;
  }
  .podcast-info-list {
    gap: 55px;
  }
  .podcast-info-gid-item {
    width: 160px;
    height: 160px;
  }
  .podcast-info-main {
    width: calc(50% - 27.5px);
  }
  .podcast-info-gid {
    width: calc(50% - 27.5px);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
  }
}
@media screen and (max-width: 1279px) {
  .podcast-author-item {
    width: 160px;
    height: 160px;
  }
  .podcast-author-item.active.item-1::before {
    width: 254px;
  }
  .podcast-author-item.active.item-1::after {
    left: 430px;
    opacity: 1;
  }
  .podcast-author-view {
    gap: 50px;
  }
  .podcast-author-heading {
    width: 50%;
    font-size: 23px;
  }
  .podcast-author-heading p * {
    font-size: 27px !important;
  }
  .podcast-info-main-image {
    max-width: 450px;
    height: 450px;
  }
  .podcast-info-list {
    gap: 30px;
  }
  .podcast-info-gid-item {
    width: 140px;
    height: 140px;
  }
  .podcast-info-main {
    width: calc(50% - 20px);
  }
  .podcast-info-gid {
    width: calc(50% - 20px);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .podcast-marquee-text {
    font-size: 80px;
  }
  .podcast-marquee {
    margin-top: 120px;
  }
  .podcast-info {
    margin: 120px 0 100px;
  }
}
@media screen and (max-width: 1023px) {
  .podcast-desc {
    font-size: 32px;
  }
  .podcast-banner {
    width: 100%;
    height: 400px;
  }
  .podcast-author-heading {
    width: 100%;
    text-align: center;
  }
  .podcast-author-item.item-1::before {
    top: 100%;
    left: 50%;
    transform: translate(-50%, 8%);
    transition: all 0.5s linear;
  }
  .podcast-author-item:not(:first-child) {
    position: relative;
    right: 0;
  }
  .podcast-author-item.active.item-1::before {
    width: 2px;
    height: 120px;
  }
  .podcast-author-item.active.item-2 {
    right: 0;
  }
  .podcast-author-item.active.item-3 {
    right: 0;
  }
  .podcast-author-item.item-1::after {
    left: 50%;
    top: 100%;
    transform: translateY(0) translateX(-25%) rotate(90deg);
  }
  .podcast-author-item.active.item-1::after {
    left: 50%;
    opacity: 1;
    top: 175%;
  }
  .podcast-author-item.active:not(:first-child) {
    transform: translateX(0%) translateY(90%);
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .podcast-author {
    padding: 100px 0;
  }
  .podcast-author-item.active {
    margin: 0 auto;
  }
  .podcast-author-view {
    display: block;
  }
  .podcast-info-list {
    display: block;
  }
  .podcast-info-main {
    width: 100%;
  }
  .podcast-info-main-image {
    margin: 0 auto 60px;
  }
  .podcast-info-gid {
    width: 100%;
    grid-row-gap: 60px;
  }
  .podcast-info-gid-item {
    margin: 0 auto;
  }
  .podcast-desc p * {
    margin-top: 50px;
    font-size: 32px !important;
    line-height: 1.3;
  }
  .podcast-author-item {
    margin: 0 auto;
  }
  .podcast-author-item.item-1::before {
    transition-delay: 2.5s;
  }
  .podcast-author-item.item-1::after {
    transition-delay: 2.5s;
  }
}
@media screen and (max-width: 767px) {
  .podcast-banner {
    height: 300px;
  }
  .podcast-marquee-text {
    font-size: 36px;
  }
  .podcast-banner-image {
    object-fit: cover;
    object-position: 84% center;
  }
  .podcast-info-main-image {
    width: 100%;
    height: 100%;
    margin: 0 auto 40px;
  }
  .podcast-info-gid {
    width: 100%;
    grid-row-gap: 20px;
    grid-column-gap: 5px;
    grid-template-columns: repeat(3, 3fr);
  }
  .podcast-info-gid-item span {
    width: 70px;
  }
  .podcast-info-gid-item {
    width: 110px;
    height: 110px;
  }
  .podcast-info {
    margin: 80px 0 80px;
  }
  .podcast-author {
    padding: 80px 0;
  }
  .podcast-desc p * {
    line-height: 1.3;
  }
  .podcast-desc p {
    margin-bottom: 20px;
  }
  .podcast-desc {
    line-height: 1.2;
    font-size: 27px;
  }
}
@media screen and (max-width: 370px) {
  .podcast-info-gid-item {
    width: 94px;
    height: 94px;
  }
}
