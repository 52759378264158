.expertpage h1 {
  margin-bottom: 20px;
}
.expertpage h2 {
  margin-bottom: 20px;
}
.expertpage .expertpage-info-item label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.expertpage .expertpage-info-item p {
  font-size: 17px;
}
.expertpage-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px 20px 0;
}
.expertpage-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
.expertpage-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
.expertpage-tags {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
.expertpage-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
.expertpage-tags span {
  font-size: 1.5rem;
}
.expertpage-tags-item {
  position: relative;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: var(--color-primary);
  color: var(--color-white);
}
.expertpage-tags-item p {
  font-size: 1rem;
  color: var(--color-white);
}
.expertpage-tags-item ul {
  text-align: center;
}
.expertpage-tags-item-delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, -50%);
  color: var(--color-white);
  background-color: var(--color-danger);
  padding: 4px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.expertpage-tags-item:hover .expertpage-tags-item-delete {
  opacity: 1;
}
.expertpage-tags-item-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, 50%);
  color: var(--color-white);
  background-color: var(--color-info-dark);
  padding: 5px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.expertpage-tags-item:hover .expertpage-tags-item-edit {
  opacity: 1;
}
