.form-project-image form {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 15px;
  margin: 0 auto 20px;
}
.form-project-flex-item select {
  display: inline-block;
  margin-bottom: 10px;
}
