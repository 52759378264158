.header.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 30px 0;
  background-color: #000000;
}
.container-fixed {
  max-width: 1400px;
  margin: 0 auto;
}
.header-list {
  display: flex;
  align-items: center;
}
.header-logo {
  position: relative;
  width: 50%;
  z-index: 10000;
}
.header-logo-image {
  width: 192px;
  height: auto;
}
.header-logo-image img {
  width: 100%;
  height: 100%;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}
.header-menu-link {
  font-size: 17px;
  font-weight: 400;
  line-height: 21.25px;
  color: #ffffff;
  transition: all 0.3s linear;
}
.header-menu-link:hover {
  color: #ea0029;
}
.header-menu-link.active {
  color: #ea0029;
}
.header-toggle {
  position: relative;
  display: none;
}
.header-menu-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.header-toggle::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 3px;
  bottom: 5px;
  transform: rotate(0deg);
  background-color: #ffffff;
  transition: 0.2s all linear;
}
.header-toggle::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: #ffffff;
  transform: rotate(0deg);
  top: 5px;
  transition: 0.2s all linear;
}
.header-toggle.is-active::before {
  transform: rotate(45deg);
  top: -2px;
}
.header-toggle.is-active::after {
  transform: rotate(-45deg);
  bottom: 0;
}
@media screen and (max-width: 1439px) {
  .container-fixed {
    max-width: 92%;
  }
}
@media screen and (max-width: 1279px) {
  .header-menu {
    gap: 35px;
  }
  .header-logo {
    font-size: 24px;
    line-height: 32.5px;
  }
}
@media screen and (max-width: 1023px) {
  .header-menu {
    padding-top: 100px;
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #000000;
    transform: translateX(100%);
    transition: all 0.3s linear;
  }
  .header-menu.is-active {
    transform: translateX(0);
  }
  .header-menu-link {
    display: block;
    font-size: 23px;
    line-height: 1.4;
    padding: 20px 15px;
    color: #ffffff;
    border: 1px solid transparent;
    text-align: center;
    margin: 0 50px 20px;
    border-radius: 20px;
  }
  .header-menu-link.active {
    border: 1px solid #ffffff;
  }
  .header-menu-item:first-child {
    margin-top: 50px;
  }
  .header-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-toggle {
    display: block;
    margin-right: 30px;
    z-index: 1001;
  }
}
@media screen and (max-width: 767px) {
  .header.is-fixed {
    padding: 20px 0;
  }
  .header-logo {
    font-size: 17px;
    line-height: 21.25px;
  }
  .header-toggle::after {
    width: 35px;
    bottom: 4px;
  }
  .header-toggle::before {
    width: 35px;
    top: 4px;
  }
  .header-menu {
    padding-top: 50px;
  }
  .header-menu-link {
    margin: 0 10px 20px;
  }
}
@media screen and (max-height: 450px) {
  .header-menu {
    max-height: 100vh; /* Thiết lập kích thước tối đa cho phần tử */
    overflow-y: auto; /* Cho phép cuộn nếu nội dung vượt quá kích thước */
    z-index: -10;
  }
}
