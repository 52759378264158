.model-banner {
  height: 700px;
  width: 50%;
  margin: 0 auto;
  position: relative;
  margin-top: 100px;
}
@media screen and (max-width: 1279px) {
  .model-banner {
    width: 60%;
  }
}
@media screen and (max-width: 1023px) {
  .model-banner {
    width: 100%;
    z-index: 0;
  }
}
@media screen and (max-width: 767px) {
  .model-banner {
    width: 100%;
    z-index: 0;
    height: 360px;
  }
}
