.contentCourse {
  margin-top: 181px;
}
.contentCourse-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.contentCourse-heading {
  width: calc(35%);
  font-size: 26px;
  line-height: 1.4;
  font-weight: 200;
}
.contentCourse-process {
  width: calc(65%);
  border: 1px solid #ffffff;
  padding: 16px 0 0;
  border-radius: 20px;
}
.contentCourse-accordion:not(:first-child) {
  border-top: 1px solid #ffffff;
  padding-top: 16px;
}
.contentCourse-accordion-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}
.contentCourse-accordion-icon {
  position: relative;
}
.contentCourse-accordion-icon::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
}
.contentCourse-accordion-icon::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(90deg);
  transition: 0.2s all linear;
}
.contentCourse-accordion-icon.is-active::before {
  transform: rotate(0deg);
}

.contentCourse-accordion-header i {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
.contentCourse-accordion-day {
  display: inline-block;
  width: calc(26% - 35px);
  margin-left: 35px;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
.contentCourse-accordion-title {
  width: 70%;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
.contentCourse-accordion-content {
  display: flex;
  /* justify-content: flex-end; */
  height: 0;
  overflow: hidden;
  transition: all 0.25s linear;
}
.contentCourse-accordion-text {
  width: 26%;
}
.contentCourse-accordion-inner {
  width: 74%;
  max-width: 600px;
  /* margin-right: 90px; */
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
.contentCourse-accordion-content.is-active {
  height: auto;
}
@media screen and (max-width: 1439px) {
  .contentCourse-accordion-inner {
    max-width: 500px;
  }
}
@media screen and (max-width: 1279px) {
  .contentCourse-list {
    display: block;
  }
  .contentCourse-heading {
    width: 100%;
    margin-bottom: 30px;
  }
  .contentCourse-process {
    width: 100%;
  }
  .contentCourse-accordion-inner {
    max-width: 670px;
  }
}
@media screen and (max-width: 767px) {
  .contentCourse {
    margin-top: 80px;
  }
  .contentCourse-heading {
    font-size: 23px;
    line-height: 1.3;
  }
  .contentCourse-accordion-day {
    width: 26%;
    margin-left: 16px;
  }
  .contentCourse-accordion-title {
    width: 58%;
  }
  .contentCourse-accordion-text {
    display: none;
  }
  .contentCourse-accordion-inner {
    width: 100%;
    padding: 0 16px;
  }
}
