.loadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #000000;
}
.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem #ea002bb5);
}

.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem #ea0029;
  animation-name: pulsIn;
}

.loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #ea0029;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #ea0029;
    opacity: 1;
  }

  50%,
  100% {
    box-shadow: inset 0 0 0 0 #ea0029;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%,
  50% {
    box-shadow: 0 0 0 0 #ea0029;
    opacity: 0;
  }

  100% {
    box-shadow: 0 0 0 1rem #ea0029;
    opacity: 1;
  }
}
