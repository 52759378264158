.blogChild {
  padding-top: 250px;
}
.blogChild-wrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 90px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  min-height: 390px;
}
.blogChild-heading {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 20px;
  color: #4285f4;
}
.blogChild-desc {
  max-width: 340px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.blogChild-image {
  position: absolute;
  right: -1px;
  top: 40%;
  transform: translateY(-50%);
  width: 800px;
}
.blogChild-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.blogChild-image iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.blogChild-post {
  padding-top: 250px;
  padding-bottom: 120px;
}
.blogChild-post-list {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-bottom: 80px;
}
.blogChild-post-item {
  position: relative;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}
.blogChild-post-item.insight:hover .blogChild-post-title {
  color: #4285f4;
}
.blogChild-post-item.thoughts:hover .blogChild-post-title {
  color: #ff7db0;
}
.blogChild-post-item::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff00;
  border-radius: 100%;
  transition: all 0.4s ease-out;
  opacity: 1;
  z-index: -1;
}
.blogChild-post-item:hover::before {
  width: 100%;
  height: 100%;
  background-color: #ffffffc5;
  opacity: 0;
  z-index: 2;
}
.blogChild-post-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  color: #ffffff;
  transition: 0.3s all linear;
}
.blogChild-pagination {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin: 80px 200px 80px 0;
}
.blogChild-pagination li {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s linear;
}
.blogChild-pagination a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  line-height: 1.4;
  font-weight: 400;
  color: #ffffff;
  transition: all 0.3s linear;
}
.blogChild-pagination li:hover,
.blogChild-pagination .active {
  background-color: #ea0029;
}
.blogChild-pagination .previous,
.blogChild-pagination .next {
  display: none;
  pointer-events: none;
}
@media screen and (max-width: 1439px) {
  .blogChild-image {
    top: 45%;
    width: 700px;
  }
  .blogChild-post-item {
    width: 270px;
    height: 270px;
  }
}
@media screen and (max-width: 1279px) {
  .blogChild {
    padding-top: 120px;
  }
  .blogChild-wrapper {
    padding: 65px;
  }
  .blogChild-post-list {
    grid-template-columns: repeat(3, 2fr);
  }
  .blogChild-image {
    top: 50%;
    width: 500px;
  }
  .blogChild-post {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 1023px) {
  .blogChild-post-list {
    grid-template-columns: repeat(2, 3fr);
  }

  .blogChild-post-list a {
    margin: 0 auto;
  }
  .blogChild-image {
    width: 320px;
  }
  .blogChild-wrapper {
    padding: 65px 30px;
  }
  .blogChild-post {
    padding-top: 200px;
  }
  .blogChild-pagination {
    justify-content: center;
    margin: 80px 0;
  }
}
@media screen and (max-width: 768px) {
  .blogChild-post-list {
    grid-template-columns: repeat(1, 3fr);
  }
  .blogChild-desc {
    max-width: 100%;
  }
  .blogChild-wrapper {
    padding: 30px 20px;
    display: block;
  }
  .blogChild-image {
    position: static;
    width: 80vw;
    transform: translateY(35%);
    margin: -40px auto 0;
  }
  .blogChild-post {
    padding-bottom: 0;
  }
}
