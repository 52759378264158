.footer-title {
  text-align: center;
  font-size: 200px;
  font-weight: 400;
  line-height: 243.75px;
  margin-bottom: 100px;
}
.footer-marquee {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%; /* Đảm bảo rằng marquee fit với chiều rộng của parent element */
}

.footer-marquee span {
  will-change: transform; /* Gợi ý cho browser về việc tối ưu hóa hiệu suất */
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  display: inline-block; /* Giúp các span xử lý đúng với animation */
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-contact-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 21.25px;
  margin-bottom: 10px;
  color: #ffffff;
}
.footer-contact-text span {
  display: inline-block;
  color: #ffffff;
}
.footer-menu {
  width: calc(50%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-list:last-child {
  margin-right: 270px;
}
.footer-link {
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.25px;
  color: #ffffff;
  margin-bottom: 10px;
  transition: all 0.3s linear;
}
.footer-list li:last-child a {
  margin-bottom: 0;
}
.footer-link:hover {
  color: #ea0029;
  transform: translateX(6px);
}
.footer-bottom {
  margin-top: 100px;
}
.footer-bottom-list {
  display: flex;
  align-items: flex-end;
  padding: 30px 0;
}
.footer-text {
  width: 50%;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.25px;
}
.footer-info {
  max-width: 350px;
  font-size: 33px;
  font-weight: 400;
  line-height: 41.25px;
}
.footer-bottom-item {
  width: 50%;
}
.footer-design {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  color: #ffffff;
  margin-bottom: 10px;
}
.footer-design a {
  color: #ffffff;
}
.footer-contact-text span {
  display: inline-block;
}
@media screen and (max-width: 1439px) {
  .footer-list:last-child {
    margin-right: 200px;
  }
}
@media screen and (max-width: 1279px) {
  .footer-title {
    font-size: 160px;
    line-height: 1.2;
  }
  .footer-list:last-child {
    margin-right: 150px;
  }
  .footer-bottom-item {
    width: 50%;
    display: block;
  }
  .footer-design {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1023px) {
  .footer-title {
    font-size: 120px;
  }
  .footer-list:last-child {
    margin-right: 40px;
  }
  .footer-marquee {
    height: 180px;
  }
}
@media screen and (max-width: 767px) {
  .footer-title {
    font-size: 72px;
    margin-bottom: 40px;
  }
  .footer-wrapper {
    display: block;
    margin-top: 20px;
  }
  .footer-menu {
    margin-top: 40px;
    width: 100%;
    display: block;
    gap: 80px;
    text-align: center;
  }
  .footer-list:last-child {
    margin-top: 40px;
    margin-right: 0px;
  }
  .footer-bottom {
    margin-top: 40px;
  }
  .footer-bottom-list {
    flex-direction: column-reverse;
    gap: 40px;
    padding: 0 0 30px 0;
  }
  .footer-text {
    width: 100%;
    text-align: center;
  }
  .footer-info {
    width: 100%;
    font-size: 24px;
    line-height: 31.25px;
    text-align: center;
    margin: 0 auto;
    max-width: 280px;
  }
  .footer-contact {
    text-align: center;
  }
  .footer-bottom-item {
    width: 100%;
  }
  .footer-design {
    text-align: center;
    margin-top: 0;
  }
  .footer-marquee {
    height: 90px;
  }
}
