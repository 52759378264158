.more-project {
  padding-top: 100px;
}
.more-project-heading {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 100px;
}
.more-project-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 140px;
}
@media screen and (max-width: 767px) {
  .more-project-list {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    grid-column-gap: 20px;
    grid-row-gap: 30px;
  }
  .more-project {
    padding-top: 40px;
  }
  .more-project-heading {
    margin-bottom: 40px;
  }
}
