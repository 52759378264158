.workpage h1 {
  margin-bottom: 20px;
}
.workpage .workpage-info-item label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 17px;
}
.workpage .workpage-info-item p {
  font-size: 17px;
}
.workpage-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px 20px 0;
}
.workpage-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
.workpage-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
.workpage-tags {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
.workpage-content {
  margin-top: 20px;
}
.workpage-content .workpage-tags-item {
  margin-bottom: 10px;
}
.workpage-tags span {
  font-size: 1.2rem;
}
.workpage-tags-item {
  position: relative;
  padding: 10px 20px;
  border-radius: 15px;
  background-color: var(--color-primary);
  color: var(--color-white);
}
.workpage-tags-item a {
  color: var(--color-white);
}
.workpage-tags-item p {
  color: var(--color-white);
  font-size: 1.1rem;
}
.workpage-tags-item-delete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, -50%);
  color: var(--color-white);
  background-color: var(--color-danger);
  padding: 4px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.workpage-tags-item:hover .workpage-tags-item-delete {
  opacity: 1;
}
.workpage-tags-item-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.6rem;
  transform: translate(50%, 50%);
  color: var(--color-white);
  background-color: var(--color-info-dark);
  padding: 5px 7px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s linear;
}
.workpage-tags-item:hover .workpage-tags-item-edit {
  opacity: 1;
}
