.project-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 20px 20px 0;
}
.project-add span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-primary);
  transition: all 0.3s linear;
}
.project-add span:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
main .project-list .project-image {
  width: 10rem;
  height: 6rem;
  margin-bottom: 0.4rem;
  border-radius: 1rem;
  margin: 10px auto;
  object-fit: cover;
}
main .project-list .project-actions {
  color: var(--color-primary);
  transition: all 0.3s linear;
}
main .project-list .project-actions:hover {
  cursor: pointer;
  color: var(--color-primary-dark);
}
main .project-pagination {
  display: flex;
  justify-content: center;
  gap: 20px;
}
main .project-pagination a {
  line-height: 1.4;
  font-weight: 400;
  transition: all 0.3s linear;
}
main .project-pagination a:hover {
  color: #000000;
}
main .project-pagination .active a {
  color: #000000;
}
