.blog {
  padding: 150px 0 180px;
}
.blog-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.blog-item {
  position: relative;
  width: 440px;
  height: 440px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #000000;
  z-index: 0;
}
.blog-item::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff00;
  border-radius: 100%;
  transition: all 0.4s ease-out;
  opacity: 1;
  z-index: -1;
}
.blog-item:hover::before {
  width: 100%;
  height: 100%;
  background-color: #ffffffa8;
  opacity: 0;
  z-index: 2;
}
.blog-insight {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  color: #4285f4;
  margin-top: 100px;
  text-align: center;
}
.blog-desc {
  margin: 0 auto;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  max-width: 340px;
  margin-top: 30px;
  color: #ffffff;
}
.blog-thoughts {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.3;
  color: #ff7db0;
  margin-top: 100px;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .blog-item {
    width: 350px;
    height: 350px;
  }
  .blog-insight {
    font-size: 23px;
    margin-top: 60px;
  }
  .blog-desc {
    font-size: 15px;
    max-width: 320px;
  }
  .blog-thoughts {
    font-size: 23px;
    margin-top: 60px;
  }
}
@media screen and (max-width: 767px) {
  .blog-list {
    display: block;
  }
  .blog-list a {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 80px;
  }
  .blog-list a:last-child {
    margin-bottom: 0;
  }
  .blog {
    padding: 150px 0 80px;
  }
  .blog-item {
    width: 300px;
    height: 300px;
  }
  .blog-insight {
    margin-top: 40px;
  }
  .blog-thoughts {
    margin-top: 40px;
  }
  .blog-desc {
    font-size: 13px;
    max-width: 260px;
    line-height: 1.35;
  }
  .formCoure-list {
    padding: 20px;
  }
}
