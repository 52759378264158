.work-detail {
  padding-top: 100px;
}
.work-detail-heading-img {
  width: 100vw;
  height: auto;
  object-fit: cover;
}

.work-detail-list {
  display: flex;
  align-items: flex-end;
  margin: 100px 0;
}
.work-detail-author {
  width: 50%;
}
.work-detail-name {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  color: #ffffff;
}
.work-detail-content {
  width: 50%;
}
.work-detail-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
}
.work-detail-desc {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 670px;
}
.work-detail-body-img {
  width: 100vw;
  height: auto;
  object-fit: cover;
  margin-bottom: 30px;
}
.work-detail-heading iframe {
  width: 99.7vw;
  height: 810px;
  object-fit: cover;
}
.work-detail-body-content {
  display: flex;
  justify-content: flex-end;
  margin: 75px 0;
}
.work-detail-body-content p {
  width: 50%;
}
.work-detail-bottom-img {
  width: 100vw;
  height: 810px;
  object-fit: cover;
}

@media screen and (max-width: 1439px) {
  .work-detail-heading-img {
    height: auto;
  }
  .work-detail-body-img {
    height: auto;
  }
  .work-detail-heading iframe {
    height: 720px;
  }
  .work-detail-bottom-img {
    height: 720px;
  }
}
@media screen and (max-width: 1279px) {
  .work-detail-heading-img {
    height: auto;
  }
  .work-detail-body-img {
    height: auto;
  }
  .work-detail-heading iframe {
    height: 575px;
  }
  .work-detail-bottom-img {
    height: 575px;
  }
}
@media screen and (max-width: 1023px) {
  .work-detail-heading-img {
    height: 430px;
  }
  .work-detail-body-img {
    height: 450px;
  }
  .work-detail-heading iframe {
    height: 450px;
  }
  .work-detail-bottom-img {
    height: 450px;
  }
  .more-project-heading {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .work-detail-heading-img {
    height: auto;
  }
  .work-detail-body-img {
    height: auto;
  }
  .work-detail-heading iframe {
    height: 240px;
  }
  .work-detail-bottom-img {
    height: 240px;
  }
  .more-project-heading {
    margin-bottom: 40px;
  }
  .work-detail-list {
    margin: 40px 0;
    flex-direction: column-reverse;
  }
  .work-detail-author p {
    margin-top: 40px;
  }
  .work-detail-author {
    width: 100%;
  }
  .work-detail-content {
    width: 100%;
  }
  .work-detail-body-content {
    margin: 40px 0;
  }
  .work-detail-body-content p {
    width: 100%;
  }
  .more-project {
    padding-top: 70px;
  }
}
