.formCourse {
  padding-bottom: 150px;
}
.formCoure-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 45px;
  border: 1px solid #ffffff;
  border-radius: 20px;
}
.formCourse-heading {
  width: 50%;
  font-size: 26px;
  font-weight: 200;
  line-height: 32.5px;
}
.formCoure-info {
  width: 50%;
}
.formCoure-input {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
}
.formCoure-input-info {
  width: 100%;
  padding: 16px;
  background-color: #000000;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  color: #ffffff;
}
.formCoure-input-item:first-child {
  grid-column: 1 / 3;
}
.formCoure-input-item:last-child {
  grid-column: 1 / 3;
}
.formCoure-button {
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  padding: 12px 24px;
  border: 1px solid transparent;
  background-color: #ffffff;
  color: #000000;
  border-radius: 20px;
  transition: all 0.3s linear;
}
.formCoure-input-item .error {
  color: red;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-left: 20px;
}
.formCoure-button:hover {
  border: 1px solid #ffffff;
  background-color: #000000;
  color: #ffffff;
}
@media screen and (max-width: 1279px) {
  .formCourse-heading {
    width: 30%;
  }
  .formCoure-info {
    width: 70%;
  }
}
@media screen and (max-width: 1023px) {
  .formCourse-heading {
    width: 100%;
    margin-bottom: 50px;
  }
  .formCoure-info {
    width: 100%;
  }
  .formCoure-list {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .formCourse {
    padding-top: 0px;
    padding-bottom: 80px;
  }
  .formCoure-input-item {
    grid-column: 1 / 3;
  }
}
