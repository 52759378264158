.homeAdmin {
  margin-top: 1.4rem;
}

main .recent-orders {
  margin-top: 1.3rem;
}

.recent-orders::-webkit-scrollbar {
  width: 6px;
  margin-left: 20px;
}
.recent-orders::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 20px;
}
.recent-orders::-webkit-scrollbar-thumb {
  background-color: var(--color-primare);
  border-radius: 20px;
}

main .recent-orders h2 {
  margin-bottom: 0.8rem;
}

main .recent-orders table {
  background-color: var(--color-white);
  width: 100%;
  padding: var(--card-padding);
  text-align: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--card-border-radius);
  transition: all 0.3s ease;
}

main .recent-orders table:hover {
  box-shadow: none;
}

main table tbody td {
  height: 2.8rem;
  border-bottom: 1px solid var(--color-light);
  color: var(--color-dark-variant);
  text-align: center;
  vertical-align: middle;
}
main .project-home tbody td img {
  width: 8rem;
  height: 5rem;
  margin-bottom: 0.4rem;
  border-radius: 1rem;
  margin: 10px auto;
}

main table tbody tr:last-child td {
  border: none;
}

main .recent-orders a {
  text-align: center;
  display: block;
  margin: 1rem auto;
  color: var(--color-primary);
}
main .recent-orders h1 {
  margin-bottom: 20px;
}
