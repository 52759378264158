.about {
  padding-top: 100px;
}
.about-banner img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}
.about-describe {
  margin: 80px 0 0 0;
}
.about-describe-content {
  font-size: 42px;
  font-weight: 400;
  line-height: 1.3;
}
.about-describe-content:first-child {
  margin-bottom: 50px;
}
.about-awards {
  padding-top: 200px;
}
.about-awards-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 60px;
}
.about-awards-wrapper {
  position: relative;
  display: flex;
}
.about-awards-list {
  width: calc(50%);
}
.about-awards-item {
  padding: 10px 0;
  border-bottom: 1px solid #ffffff;
  transition: all 0.3s linear;
  cursor: pointer;
}
.about-awards-item.first {
  border-top: 1px solid #ffffff;
}
.about-awards-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-awards-title {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.about-awards-date {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  margin-right: 20px;
}
.about-awards-content {
  position: absolute;
  display: flex;
  gap: 20px;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s linear;
  pointer-events: none;
}
.about-awards-content.first {
  opacity: 1;
}
.about-awards-img {
  width: 270px;
  height: 300px;
  object-fit: cover;
  margin: 0 auto;
}
.about-awards-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 390px;
}
.about-awards-item:hover .about-awards-content {
  opacity: 1;
}
.about-awards-item.first:hover .about-awards-content.first {
  opacity: 1;
}
.about-awards-list:hover
  .about-awards-item.first:not(:hover)
  .about-awards-content.first {
  opacity: 0;
}

/* .about-awards-list:hover .about-awards-item.first:not(:hover) {
  opacity: 0.2;
} */
.about-awards-list:hover .about-awards-item:not(:hover) {
  opacity: 0.2;
}
.about-story {
  padding-top: 200px;
}
.about-story-wrapper {
  display: flex;
  gap: 60px;
}
.about-story-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 70px;
  margin-left: 50px;
}
.about-story-image {
  width: calc(50% - 30px);
}
.about-story-content {
  width: calc(50% - 30px);
}
.about-story-image-main {
  width: 320px;
  height: 320px;
  object-fit: cover;
  border-radius: 50%;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.about-story-image-list {
  display: grid;
  grid-template-columns: repeat(4, 2fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.about-story-image-item {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  transition: all 0.5s linear;
}
.about-story-image-item:hover {
  transform: rotateY(180deg);
}
.about-story-heading {
  width: 50%;
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
}
.about-story-desc {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 30px;
}
.about-education {
  padding-top: 200px;
}
.about-education-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 70px;
}
.about-education-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 50px;
}
.about-education-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}
.about-education-title {
  font-size: 17px;
  font-weight: 900;
  line-height: 1.3;
}
.about-education-position {
  width: 220px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.about-education-time {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.about-ex {
  padding-top: 200px;
  padding-bottom: 200px;
}
.about-ex-top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 70px;
}
.about-ex-heading {
  width: calc(50% - 20px);
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
}
.about-ex-wrapper {
  display: flex;
  gap: 40px;
}
.about-ex-image {
  width: calc(50% - 20px);
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.about-ex-item {
  border-radius: 50%;
  background-color: #ea0029;
  width: 150px;
  height: 150px;
  transition: 0.5s all linear;
  position: relative;
}
.about-ex-item img {
  border-radius: 50%;
  object-fit: cover;
}
.about-ex-img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.about-ex-item:hover:not(.about-ex-item.item-1, .about-ex-item.item-6) {
  transform: rotateY(180deg);
}
.about-ex-item.item-1 {
  width: 320px;
  height: 320px;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.about-ex-item.item-6 {
  width: 320px;
  height: 320px;
  grid-column: 1 / 3;
  grid-row: 3 / 5;
}
.about-ex-item .about-ex-item-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}

.about-ex-content {
  width: calc(50% - 20px);
  border-top: 1px solid #ffffff;
  padding: 16px 0 0 0;
}
.about-ex-accordion:not(:first-child) {
  border-top: 1px solid #ffffff;
  padding-top: 16px;
}
.about-ex-accordion:last-child {
  border-bottom: 1px solid #ffffff;
}
.about-ex-accordion-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
}
.about-ex-accordion-icon {
  position: relative;
  margin-right: 20px;
}
.about-ex-accordion-icon::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
}
.about-ex-accordion-icon::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(90deg);
  transition: 0.2s all linear;
}
.about-ex-accordion-icon.is-active::before {
  transform: rotate(0deg);
}
.about-ex-accordion-content {
  display: flex;
  height: 0;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.25s linear;
}
.about-ex-accordion-title {
  width: 50%;
}
.about-ex-accordion-list {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-ex-accordion-text {
  max-width: 250px;
  opacity: 1;
  transition: 0.1s all linear;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}
.about-ex-accordion-text.is-active {
  max-width: 250px;
  opacity: 0;
}
.about-ex-accordion-inner {
  width: 50%;
  max-width: 350px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
}
.about-ex-accordion-desc {
  width: 50%;
  max-width: 240px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}
.about-ex-accordion-content.is-active {
  height: auto;
}
.about-resume {
  padding-bottom: 200px;
}
.about-resume-heading {
  font-size: 26px;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 90px;
}
.about-resume-title {
  font-size: 23px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 20px;
}
.about-resume-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 30px;
  transition: all 0.3s linear;
  color: #ffffff;
}
.about-resume-text:hover {
  color: #ea0029;
}
@media screen and (max-width: 1439px) {
  .about-awards-list {
    width: calc(40%);
  }
  .about-story-image-item {
    width: 120px;
    height: 120px;
  }
  .about-story-image-main {
    width: 260px;
    height: 260px;
  }
  .about-ex-item {
    width: 130px;
    height: 130px;
  }
  .about-ex-item.item-1 {
    width: 280px;
    height: 280px;
  }
  .about-ex-item.item-6 {
    width: 280px;
    height: 280px;
  }
}
@media screen and (max-width: 1279px) {
  .about-story-heading {
    width: 100%;
  }
  .about-story-top {
    justify-content: flex-start;
    margin-left: 0;
  }
  .about-awards-wrapper {
    display: block;
  }
  .about-awards {
    height: auto;
  }
  .about-awards-list {
    width: 100%;
  }
  .about-awards-content {
    position: static;
    gap: 70px;
    height: 0;
    overflow: hidden;
    opacity: 1;
  }
  .about-awards-content.is-active {
    height: auto;
  }
  .about-awards-item.is-active {
    opacity: 1;
  }
  .about-awards-item {
    opacity: 0.2;
  }
  .about-awards-img {
    margin-top: 30px;
  }
  .about-awards-text {
    max-width: 600px;
    margin-top: 30px;
  }
  .about-story-wrapper {
    display: flex;
    gap: 60px;
    flex-direction: column-reverse;
  }
  .about-story-content {
    width: 100%;
  }
  .about-story-image {
    width: 100%;
  }
  .about-story-image-item {
    width: 200px;
    height: 200px;
    margin: 0 auto;
  }
  .about-story-image-main {
    width: 460px;
    height: 460px;
  }
  .about-education-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-ex-wrapper {
    display: block;
  }
  .about-ex-image {
    width: 100%;
    margin-bottom: 50px;
  }
  .about-ex-item {
    margin: 0 auto;
  }
  .about-ex-item {
    width: 150px;
    height: 150px;
  }
  .about-ex-item.item-1 {
    width: 320px;
    height: 320px;
  }
  .about-ex-item.item-6 {
    width: 320px;
    height: 320px;
  }
  .about-ex-content {
    width: 100%;
  }
  .about-ex-accordion-inner {
    max-width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .about-describe-content {
    font-size: 36px;
  }
  .about-story-image-main {
    width: 340px;
    height: 340px;
  }
  .about-story-image-item {
    width: 150px;
    height: 150px;
  }
  .about-banner img {
    height: 80vh;
  }
}
@media screen and (max-width: 767px) {
  .about-ex-image {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
  .about-banner img {
    height: 100%;
  }
  .about-ex-top {
    margin-bottom: 60px;
  }
  .about-education-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .about-awards-img {
    margin-bottom: 20px;
  }
  .about-ex-item.item-1 {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .about-ex-item.item-6 {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .about-ex-item {
    width: 100%;
    height: 100%;
  }
  .about-banner {
    height: 350px;
  }
  .about-describe-content {
    font-size: 23px;
    line-height: 1.3;
    margin-bottom: 30px;
  }
  .about-describe {
    margin: 60px 0 0 0;
  }
  .about-awards-date {
    font-size: 15px;
    margin-right: 0px;
  }
  .about-awards-title {
    font-size: 15px;
  }
  .about-awards {
    height: auto;
    padding-top: 60px;
  }
  .about-awards-content {
    display: block;
  }
  .about-story {
    padding-top: 100px;
  }
  .about-story-image-list {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .about-story-image-main {
    width: 100%;
    height: 100%;
    margin: 10px auto 0;
  }
  .about-story-image-item {
    width: 170px;
    height: 170px;
  }
  .about-education {
    padding-top: 100px;
  }
  .about-education-heading {
    margin-bottom: 60px;
  }
  .about-ex {
    padding: 80px 0;
  }
  .about-ex-heading {
    width: 100%;
  }
  .about-ex-accordion-content {
    display: block;
  }
  .about-ex-accordion-desc {
    width: 100%;
    margin-bottom: 20px;
  }
  .about-ex-accordion-inner {
    width: 100%;
  }
  .about-ex-accordion-list {
    width: 10%;
  }
  .about-ex-accordion-title {
    width: 90%;
  }
  .about-ex-accordion-text {
    display: none;
  }
  .about-resume {
    padding-bottom: 80px;
  }
  .about-resume-heading {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 334px) {
  .about-story-image-item {
    width: 140px;
    height: 140px;
  }
}
