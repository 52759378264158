.form-project form {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 15px;
  margin-bottom: 20px;
  display: block;
  grid-column-gap: 10px;
}
.form-project-item {
  margin-bottom: 30px;
}
.form-project-item label {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
}
.form-project-item input {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
}
.form-project-item input::placeholder {
  opacity: 0.5;
}
.form-project-item textarea {
  width: 100%;
  padding: 20px;
  border-radius: 15px;
}
.form-project-item .error {
  color: red;
  margin-left: 10px;
  margin-top: 10px;
}
.form-project-item button {
  padding: 14px 34px;
  border-radius: 15px;
  background-color: var(--color-info-dark);
  color: var(--color-white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.form-project-item button:hover {
  background-color: var(--color-info-light);
  color: var(--color-dark);
}
.form-project-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.form-project-item.first-item {
  grid-column: 1/3;
}
.form-project-flex-img iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.form-project-flex-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.form-project-flex-img {
  position: relative;
  width: 360px;
  height: 200px;
  background-color: var(--color-light);
  border-radius: 15px;
}
.form-project-flex-img p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: var(--color-info-dark);
  color: var(--color-white);
  border-radius: 5px 5px;
  cursor: pointer;
}
.form-project-item select {
  padding: 20px;
  border-radius: 15px;
}
.form-project-item input[type='file'] {
  padding: 16px;
  border-radius: 15px;
  border: 1px solid var(--color-dark);
}
.ql-picker-options {
  max-height: 300px;
  overflow-y: auto;
}
.quill .ql-snow .ql-picker.ql-lineHeight {
  width: 98px;
  display: inline-block;
}
.quill .ql-snow .ql-picker.ql-lineHeight .ql-picker-label {
  width: 100%;
}
.quill .ql-snow .ql-picker.ql-lineHeight .ql-picker-label::before {
  content: 'LHeight ';
}
.quill .ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: 'Size ';
}
.quill .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '';
}
.quill .ql-editor {
  max-height: 500px;
  overflow-y: auto;
}
.project-loading {
  font-size: 20px;
  margin-left: 50px;
}
